import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from './api.service';

export type LumDocumentationType = 'connector' | 'frontend';

@Injectable({
  providedIn: 'root',
})
export class DocumentationService {
  constructor(private readonly apiService: ApiService) {}

  public fetchDocumentation(
    type: LumDocumentationType
  ): Promise<{ [propName: string]: unknown } | undefined> {
    return firstValueFrom(
      this.apiService.get<{ [propName: string]: unknown } | undefined>({
        endpoint: 'v3/api-docs/' + type,
        responseType: 'json',
      })
    );
  }

  public fetchSignedCookie(): Promise<void> {
    // const cookies = firstValueFrom(
    //   this.apiService.get<unknown>({
    //     endpoint: 'hello/docs/cookies',
    //     withCredentials: true,
    //   })
    // );
    // console.warn('fetchAndSetSignedCookie', cookies);

    return Promise.resolve();
  }

  public downloadApiJsonFile(type: LumDocumentationType): Promise<void> {
    return firstValueFrom(
      this.apiService.downloadFile(
        'v3/api-docs/' + type,
        'application/json',
        'lumeso-api-' + type + '.json'
      )
    );
  }

  public downloadDeveloperGuideZip(): Promise<void> {
    return firstValueFrom(
      this.apiService.downloadFile(
        'docs/guides/developer-guide.zip',
        'application/zip',
        'lumeso-developer-guide.zip'
      )
    );
  }
}

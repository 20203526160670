import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LUM_ENV } from '@lum-environment';
import { ToastMessageService } from '@lum-services';
import { LumToastMessageType } from '@lum-types';
import { Observable, catchError, map, tap } from 'rxjs';
import { LumApiEndpoint, LumApiPath } from '../../types/api.type';
import { FileUtils } from '../../utils/file.utils';
import { AuthService } from '../auth.service';

export type LumRequestParams = { [s: string]: string };
export type LumRequestToastMessages = {
  success?: LumRequestToastMessage;
  error?: LumRequestToastMessage;
};
export type LumRequestToastMessage = {
  title?: string;
  message?: string;
};

export type LumRequestData = {
  method?: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';
  endpoint: LumApiEndpoint | string;
  path?: LumApiPath;
  id?: string | number;
  id2?: string | number;
  body?: unknown;
  headers?: LumRequestParams;
  queryParams?: LumRequestParams;
  version?: string;
  responseType?: 'json' | 'text' | 'arraybuffer';
  toastMessage?: LumRequestToastMessages;
  withCredentials?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUri = LUM_ENV.api.baseUrl;

  constructor(
    public readonly authService: AuthService,
    private readonly httpClient: HttpClient,
    private readonly toastMessageService: ToastMessageService
  ) {}

  public get<ResponseData>(data: LumRequestData): Observable<ResponseData> {
    return this.request<ResponseData>({ ...data, method: 'GET' });
  }

  public put<ResponseData>(data: LumRequestData): Observable<ResponseData> {
    return this.request<ResponseData>({ ...data, method: 'PUT' }).pipe(
      tap(() => {
        this.triggerToastMessage('success', data.toastMessage?.success);
      }),
      catchError((error) => {
        this.triggerToastMessage('error', data.toastMessage?.error, error);
        throw error;
      })
    );
  }

  public post<ResponseData>(data: LumRequestData): Observable<ResponseData> {
    return this.request<ResponseData>({ ...data, method: 'POST' }).pipe(
      tap(() => {
        this.triggerToastMessage('success', data.toastMessage?.success);
      }),
      catchError((error) => {
        this.triggerToastMessage('error', data.toastMessage?.error, error);

        throw error;
      })
    );
  }

  public patch<ResponseData>(data: LumRequestData): Observable<ResponseData> {
    return this.request<ResponseData>({ ...data, method: 'PATCH' }).pipe(
      catchError((error) => {
        this.triggerToastMessage('error', data.toastMessage?.error, error);
        throw error;
      })
    );
  }

  public delete<ResponseData>(data: LumRequestData): Observable<ResponseData> {
    return this.request<ResponseData>({ ...data, method: 'DELETE' }).pipe(
      catchError((error) => {
        this.triggerToastMessage('error', data.toastMessage?.error, error);
        throw error;
      })
    );
  }

  public downloadFile(
    endpoint: string,
    type: 'application/zip' | 'application/json',
    filename: string
  ): Observable<void> {
    return this.request<BlobPart>({
      method: 'GET',
      endpoint,
      responseType: 'arraybuffer',
    }).pipe(
      map((data) => {
        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        FileUtils.downloadFile(url, filename);
      }),
      catchError((error) => {
        this.triggerToastMessage('error', undefined, error);
        throw error;
      })
    );
  }

  private request<ResponseData>(
    data: LumRequestData
  ): Observable<ResponseData> {
    return this.httpClient
      .request(data.method!, this.setUrl(data), {
        headers: data.headers,
        params: data.queryParams,
        body: data.body,
        responseType: data.responseType ?? 'json',
        observe: 'response',
        withCredentials: data.withCredentials ?? false,
      })
      .pipe(map((event) => event.body as ResponseData));
  }

  private setUrl(data: LumRequestData): string {
    let url = `${this.baseUri}/${data.endpoint}`;

    // set path if given
    if (data.path) {
      url += '/' + data.path;

      // replace id if needed
      if (data.path.includes('{id}') && data.id) {
        url = url.replace('{id}', data.id.toString());
      }

      // replace id2 if needed
      if (data.path.includes('{id2}') && data.id2) {
        url = url.replace('{id2}', data.id2.toString());
      }
    }

    return url;
  }

  private triggerToastMessage(
    type: LumToastMessageType,
    toastMessage?: LumRequestToastMessage,
    error?: HttpErrorResponse
  ): void {
    let message = toastMessage?.message;
    if (!['production', 'staging'].includes(LUM_ENV.env) && !message) {
      // hide BE error message for staging and production environment
      message = error?.error.message;
    }

    this.toastMessageService.triggerToast({
      type,
      message: message ? message : undefined,
      title: toastMessage?.title ? toastMessage.title : undefined,
    });
  }
}
